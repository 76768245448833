import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import MembersDashboard from './pages/MembersDashboard';
import WaiversDashboard from './pages/WaiversDashboard';
import EventsDashboard from './pages/EventsDashboard';
import LandingPage from './pages/LandingPage';
import { setAuthToken } from './services/api'; // Import the function to set the authorization token

const AppContent = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tokens, setTokens] = useState(null); // Store tokens here
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to get the current URL pathname and search (without domain)
  const getCurrentPath = () => {
    return `${location.pathname}${location.search}`; // e.g., /members?email=trwarner53@yahoo.com
  };

  // Function to save tokens to localStorage
  const saveTokensToLocalStorage = (data) => {
    localStorage.setItem('accessToken', data.access_token);
    localStorage.setItem('refreshToken', data.refresh_token);
  };

  // Function to load tokens from localStorage
  const loadTokensFromLocalStorage = () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (accessToken) {
      setTokens({ access_token: accessToken, refresh_token: refreshToken });
      setAuthToken(accessToken);
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    const exchangeCodeForToken = async (code, redirectPath) => {
      try {
        const response = await fetch('https://api.townoffun.com/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code,
            redirect_uri: 'https://dashboard.townoffun.com',
          }),
        });
    
        if (!response.ok) {
          throw new Error('Token exchange failed');
        }
    
        const data = await response.json();
        setTokens(data);
        setAuthToken(data.access_token);
        setIsAuthenticated(true);
        saveTokensToLocalStorage(data); // Save tokens to localStorage
        navigate(redirectPath || '/landing');
      } catch (error) {
        console.error('Error exchanging code for token:', error);
      }
    };
    
    // Check if there is a valid session in localStorage
    if (!isAuthenticated) {
      loadTokensFromLocalStorage(); // Load tokens from localStorage
    }

    if (!isAuthenticated) {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      const redirectPath = searchParams.get('state'); // Get the original path stored in 'state'

      if (code) {
        console.log('Auth code from URL:', code);
        exchangeCodeForToken(code, redirectPath); // Exchange the code for tokens and redirect
      } else {
        const currentPath = getCurrentPath(); // Get the current path to redirect back to after login
        const cognitoLoginUrl = `https://tof.auth.us-east-1.amazoncognito.com/login?client_id=hm2am1df2p6m7rrbfq56ha9eg&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fdashboard.townoffun.com&state=${encodeURIComponent(currentPath)}`;
        window.location.href = cognitoLoginUrl; // Redirect to Cognito login with original path in state
      }
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, location, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/members" element={<MembersDashboard />} />
        <Route path="/waivers" element={<WaiversDashboard tokens={tokens} />} />
        <Route path="/events" element={<EventsDashboard />} />
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <Router> {/* Make sure the Router is wrapping everything */}
      <AppContent />
    </Router>
  );
};

export default App;
