import axios from 'axios';

const API_BASE_URL = 'https://api.townoffun.com';

export const setAuthToken = (token) => {
    console.log('Setting auth token:', token);
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        console.log('Authorization header set');
    } else {
        delete axios.defaults.headers.common['Authorization'];
        console.log('Authorization header removed');
    }
};

export const getMembers = async () => {
    return axios.get(`${API_BASE_URL}/members`);
};

export const getWaivers = async () => {
    return axios.get(`${API_BASE_URL}/waivers`);
};

export const getEventsToday = () => {
    return axios.get(`${API_BASE_URL}/reservations/today`);
};

// New Function: Get events by date range
export const getEventsByDateRange = async (fromDate, toDate) => {
    // Construct the query string with the 'from' and 'to' date parameters
    const params = new URLSearchParams({
        from: fromDate,
        to: toDate,
    });

    // Send a GET request with the date range query parameters
    return await axios.get(`${API_BASE_URL}/reservations?${params.toString()}`);
};


export const updateReservation = (reservation) => {
    return axios.put(`${API_BASE_URL}/reservations`, reservation);
};

export const getActiveMembers = () => {
    return axios.get(`${API_BASE_URL}/subscriptions/active`);
};

export const getCancelledMembers = () => {
    return axios.get(`${API_BASE_URL}/subscriptions/cancelled`);
};

export const getMemberByPhoneOrEmail = (query) => {
    return axios.get(`${API_BASE_URL}/subscriptions/member`, { params: query });
};

export const addWalkIn = async (walkInData) => {
    return await axios.post(`${API_BASE_URL}/walkins`, walkInData);
};